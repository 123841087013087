import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static targets = ["something"]

  connect() {
    var el = $(this.element);

    if(el.hasClass("selectpicker-initialized")){
    	return
    }

		var selected = el.data('selected');
		if(selected != undefined){
		  el.val(selected.toString().split(','));              
		  el.selectpicker('render');         
		}
		else
		  el.selectpicker();   

		el.addClass("selectpicker-initialized");
  }
}
